import Hero from "./sections/Hero";
import OurCustomers from "./sections/OurCustomers";
import Tech from "./sections/Tech";
import StartWithUs from "./sections/StartWithUs";
import Integration from "./sections/Integration";
import WhyUs from "./sections/WhyUs";
import WhatWeDo from "./sections/WhatWeDo";

const Main = () => {
  return (
    <>
      <Hero />
      <WhatWeDo />
      <WhyUs />
      <OurCustomers />
      <Tech />
      <StartWithUs />
      <Integration />
    </>
  );
};

export default Main;
