import { Box, Grid, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";

import One from "../assets/One.png";
import Two from "../assets/Two.png";
import Three from "../assets/Three.png";
import Four from "../assets/Four.png";
import Five from "../assets/Five.png";
import Six from "../assets/Six.png";
import Seven from "../assets/Seven.png";
import Eight from "../assets/Eight.png";
import Nine from "../assets/Nine.png";
import Ten from "../assets/Ten.png";
import Eleven from "../assets/Eleven.png";
import Twelve from "../assets/Twelve.png";
import Thirteen from "../assets/Thirteen.png";
import Fourteen from "../assets/Fourteen.png";
import Fifteen from "../assets/Fifteen.png";
import Sixteen from "../assets/Sixteen.png";
import Seventeen from "../assets/Seventeen.png";
import Eighteen from "../assets/Eighteen.png";
import Nineteen from "../assets/Nineteen.png";
import Twenty from "../assets/Twenty.png";
import Twentyone from "../assets/Twentyone.png";
import Twentytwo from "../assets/Twentytwo.png";
import Twentythree from "../assets/Twentythree.png";
import Twentyfour from "../assets/Twentyfour.png";
import Twentyfive from "../assets/Twentyfive.png";
import Twentysix from "../assets/Twentysix.png";
import Twentyseven from "../assets/Twentyseven.png";
import Twentyeight from "../assets/Twentyeight.png";
import Twentynine from "../assets/Twentynine.png";
import Thirty from "../assets/Thirty.png";

const allImages = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  Twentyone,
  Twentytwo,
  Twentythree,
  Twentyfour,
  Twentyfive,
  Twentysix,
  Twentyseven,
  Twentyeight,
  Twentynine,
  Thirty,
];

type SquareCardProps = {
  imageUrl: string;
};

const SquareCard: React.FC<SquareCardProps> = ({ imageUrl }) => (
  <Box w="100%" padding="1rem" borderRadius="md" boxShadow="lg" bg="white">
    <Box position="relative" w="100%" paddingBottom="100%">
      <Image
        src={imageUrl}
        alt="Integration"
        objectFit="cover"
        borderRadius="md"
        position="absolute"
        w="100%"
        h="100%"
      />
    </Box>
  </Box>
);

const Integration: React.FC = () => (
  <Box padding={{ base: "2rem", lg: "4rem" }} textAlign="center">
    <Text
      fontSize={["sm", "md"]}
      fontWeight="bold"
      mb={2}
      textAlign="center"
      color="barbie.400"
    >
      OUR TECH
    </Text>
    <Heading marginBottom="2rem" color="action.400">
      Easy to integrate
    </Heading>
    <Text fontSize="xl" mb={6}>
      There is no need to tech or pipeline changes. It itegrates with your stack
      out of the box
    </Text>
    <Grid
      templateColumns={{
        base: "repeat(3, 1fr)",
        sm: "repeat(5, 1fr)",
        md: "repeat(8, 1fr)",
        lg: "repeat(15, 1fr)", // Set this to have 15 columns on large screens
      }}
      gap={6}
    >
      {Array.from({ length: 30 }, (_, index) => (
        <SquareCard key={index} imageUrl={allImages[index]} />
      ))}
    </Grid>
  </Box>
);

export default Integration;
