import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Portal,
  Box,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

const OverlayAlert: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Portal>
      <Box
        position="fixed"
        right={4}
        bottom={4}
        maxWidth={["300px", "400px"]}
        width="100%"
        zIndex="toast"
      >
        <Alert status="success" bg="background.700">
          <AlertIcon />
          <AlertTitle mr={2} color="white">
            Contact us: &nbsp;
            <Text
              as="span"
              color="barbie.400"
              _hover={{ cursor: "pointer" }}
              onClick={() => onClickContactUs()}
            >
              contact@guardhash.com
            </Text>
          </AlertTitle>
          <CloseButton
            onClick={() => setIsVisible(false)}
            bg="barbie.400"
            color="white"
            size="sm"
          />
        </Alert>
      </Box>
    </Portal>
  );
};

export default OverlayAlert;
