import React from "react";
import { Box, Text, SimpleGrid, Image } from "@chakra-ui/react";

import AbstractImageOne from "../../../assets/AbstractOne.png";
import AbstractImageTwo from "../../../assets/AbstractTwo.png";
import AbstractImageThree from "../../../assets/AbstractThree.png";
import AbstractImageFour from "../../../assets/AbstractFour.png";
import AbstractImageFive from "../../../assets/AbstractFive.png";

const WhyUs: React.FC = () => {
  const cards = [
    {
      title: "Enhance Business Insights",
      description:
        "Enable teams to get precise analytical insights concerning products, clientele, and operations, thus sharpening decision-making processes.",
      imageUrl: AbstractImageTwo,
    },
    {
      title: "Boost AI Integration",
      description:
        "Use private and sensitive data in a secure way to develop and train the algorithms central to your AI functionalities.",
      imageUrl: AbstractImageOne,
    },
    {
      title: "Monetize Organizational Data",
      description:
        "Discover innovative approaches to commercialize your data, revamping your propositions and carving out fresh avenues for revenue.",
      imageUrl: AbstractImageThree,
    },
    {
      title: "Easy data partnerships",
      description:
        "Facilitate swift data sharing both within and outside the organization to affirm new products and partners, speed up creative endeavors, and enhance client value.",
      imageUrl: AbstractImageFour,
    },
    {
      title: "Use production data in safety",
      description:
        "Empower the use of production data without fears. Increase the validation of novel systems and technologies, and propelling your organization's digital evolution.",
      imageUrl: AbstractImageFive,
    },
  ];

  return (
    <Box p={[4, 8]} textAlign="center" backgroundColor="black">
      <Text fontSize={["sm", "md"]} fontWeight="bold" mb={2} color="barbie.400">
        WHY TO CHOOSE US
      </Text>
      <Text fontSize="4xl" fontWeight="bold" mb={4} color="action.400">
        OUR OFFERS
      </Text>
      <Text fontSize="xl" mb={6} color="white">
        Discover the unique benefits we offer and how we stand out in the
        industry.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} w="80%" mx="auto">
        {cards.map((card, index) => (
          <Box
            key={index}
            borderRadius="md"
            p={[0, 4]}
            bg="grey.300"
            textAlign="left"
          >
            <Image
              src={card.imageUrl}
              alt={card.title}
              objectFit="cover"
              w="40px%"
              h="40px"
              mb={4}
              borderRadius="xl"
            />
            <Text fontSize="xl" fontWeight="bold" mb={2} color="action.400">
              {card.title}
            </Text>
            <Text color="white">{card.description}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default WhyUs;
