import { extendTheme } from "@chakra-ui/react";

const Theme = extendTheme({
  colors: {
    background: {
      50: "#7a72a8", // Lightest
      100: "#6a61a0",
      200: "#5a5098",
      300: "#4a3f90",
      400: "#3a2e88", // Mid-tone (original color)
      500: "#2a1d80", // Slightly darker
      600: "#1a0c78",
      700: "#150240", // Original color
      800: "#0c021a",
      900: "#030113", // Darkest
    },
    action: {
      50: "#e6e7ff", // Lightest
      100: "#c4c6ff",
      200: "#a2a5ff",
      300: "#8084ff",
      400: "#5e63ff", // Mid-tone (original color)
      500: "#4c50cc", // Slightly darker
      600: "#3a3d99",
      700: "#282a66",
      800: "#161833", // Darkest
      900: "#040613", // Almost black
    },
    barbie: {
      50: "#fff5ff", // Lightest
      100: "#ffd3ff",
      200: "#ffb0ff",
      300: "#ff8dff",
      400: "#FF96FF", // Mid-tone (original color)
      500: "#e482e4",
      600: "#c96ec9",
      700: "#ae5aae",
      800: "#923e92", // Darkest
      900: "#761c76", // Almost black
    },
  },
});

export default Theme;
