import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

import AbstractOne from "../../../assets/AbstractOne.png";
import AbstractTwo from "../../../assets/AbstractTwo.png";
import AbstractThree from "../../../assets/AbstractThree.png";
import { AvailablePage } from "containers/Page/Page";
import { useNavigate } from "react-router-dom";

const boxItems = [
  {
    title: "Enhanced Security and Simplified Compliance Reviews",
    description:
      "With GuardHash, data users don't directly interact with the primary data, eliminating the need for data anonymization or desensitization. This minimizes data leakage risks and simplifies internal operations, leading to swifter decision-making, shorter data acquisition times, and a higher project success rate.",
    logo: AbstractOne,
  },
  {
    title: "Optimal Data Loss Protection",
    description:
      "Once data is exported, security teams often face challenges in preventing its misuse, leakage, or loss. GuardHash ensures that sensitive information remains internal, permitting only secure outputs for export. This offers an unparalleled solution for managing insider threats across all Analytics and AI tasks.    ",
    logo: AbstractTwo,
  },
  {
    title: "Comprehensive Data Use Tracking and Auditing",
    description:
      "GuardHash removes the necessity of copying data externally, granting data proprietors the ability to monitor each data processing request. Every computation gets logged, allowing for a thorough examination and auditing of all outputs derived from private data.",
    logo: AbstractThree,
  },
];

const Description: React.FC = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" }) as
    | "column"
    | "row"
    | undefined;

  const navigate = useNavigate();

  return (
    <Flex direction={flexDirection}>
      {/* Left Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          textAlign="center"
          color="barbie.600"
        >
          FINANCE
        </Text>
        <Text fontSize="3xl" fontWeight="bold" color="action.400">
          Financial Services Security and Privacy
        </Text>
        <Text color="gray.800">
          GuardHash offers top-tier data protection tools tailored for financial
          firms, empowering them to maximize the use of sensitive data. These
          solutions not only expedite data projects but also streamline security
          and regulatory protocols. By integrating the pinnacle of data privacy
          and security, analytic and AI processes are enhanced, boosting the
          organization's overall adaptability.
        </Text>
        <Text color="gray.800">
          Financial institutions, including banks and insurance companies, can
          now utilize private data across different regions and legal
          structures. This capability facilitates AI training, crafting of
          marketing approaches, refining risk assessments, and collaborating
          with fellow banks for more effective financial crime detection.
        </Text>
        <Button
          variant="ghost"
          bg="action.400"
          color="white"
          _hover={{ bg: "action.600" }}
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
        <Box
          mt={12}
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          padding="1rem"
          width="100%"
          textAlign="center" // Centering the text inside the box
          backgroundColor="barbie.100"
        >
          <Text fontSize="xl" fontWeight="bold" mb={2} color="action.700">
            Success stories
          </Text>
          <Button
            bg="action.400"
            color="white"
            variant="solid"
            _hover={{ bg: "action.600" }}
            mt="2"
            onClick={() => navigate(AvailablePage.FinanceUseCase)}
          >
            Explore success stories
          </Button>
        </Box>
      </VStack>

      {/* Right Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        {boxItems.map((box, idx) => (
          <Box
            key={idx}
            padding="1rem"
            borderWidth="1px"
            borderRadius="md"
            width="100%"
          >
            <HStack spacing={2} alignItems="center">
              <Image
                src={box.logo}
                alt={box.title}
                boxSize="40px"
                borderRadius="40%"
              />
              <VStack alignItems="start" spacing={1}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="action.400"
                  mb={4}
                >
                  {box.title}
                </Text>
                <Text color="gray.800">{box.description}</Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
};

export default Description;
