import ContactUsBanner from "./sections/ContactUsBanner";
import Description from "./sections/Description";

const MarketingPage = () => {
  return (
    <>
      <Description />
      <ContactUsBanner />
    </>
  );
};

export default MarketingPage;
