import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  Center,
  VStack,
  Grid,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";
import { AvailablePage } from "containers/Page/Page";
import { useNavigate } from "react-router-dom";
import FinanceImage from "../assets/FinanceImage.png";
import MarketingImage from "../assets/MarketingImage.png";
import HealthCareImage from "../assets/HealthCareImage.png";
import SoftwareImage from "../assets/SoftwareImage.png";

const OurCustomers: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();

  const cardItems = [
    {
      imageSrc: FinanceImage,
      title: "Finance",
      buttonText: "Find out more",
      onClickCard: () => navigate(AvailablePage.Finance),
    },
    {
      imageSrc: MarketingImage,
      title: "Marketing",
      buttonText: "Find out more",
      onClickCard: () => navigate(AvailablePage.Marketing),
    },
    {
      imageSrc: HealthCareImage,
      title: "Healthcare",
      buttonText: "Find out more",
      onClickCard: () => navigate(AvailablePage.HealthCare),
    },
    {
      imageSrc: SoftwareImage,
      title: "Software",
      buttonText: "Find out more",
      onClickCard: () => navigate(AvailablePage.SoftwareEngineering),
    },
  ];

  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row-reverse"}
      align="center"
      justify="center"
      w="100%"
      bg="white"
      overflow="hidden"
      px={[4, 20]}
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 8]}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          color="barbie.400"
          mb={2}
        >
          OUR CUSTOMERS
        </Text>
        <Text
          fontSize={["3xl", "4xl"]}
          fontWeight="bold"
          mb={4}
          color="action.400"
        >
          FINANCE, MARKETING, HEALTHCARE AND MUCH MORE
        </Text>
        <Text fontSize={["md", "lg"]} mb={6}>
          We extend our expertise across a diverse range of sectors, from the
          intricacies of finance and the critical nature of healthcare to the
          dynamic realms of marketing and software engineering. Our tailored
          solutions address the unique challenges and needs of each industry,
          ensuring optimal outcomes and driving innovation across the board.
        </Text>
        <Center w={isMobile ? "100%" : "auto"}>
          <Button
            variant="ghost"
            bg="action.400"
            color="white"
            _hover={{ bg: "action.600" }}
            size="lg"
            onClick={onClickContactUs}
          >
            Contact us
          </Button>
        </Center>
      </Box>
      <Box flex="1" display={isMobile ? "none" : "block"} my={20} p={[4, 8]}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }} // 1 column for mobile, 2 columns for desktop
          gap={4}
        >
          {cardItems.map((card, idx) => (
            <VStack
              key={idx}
              spacing={4}
              w="100%"
              alignItems="center" // Center content horizontally
              borderWidth="1px" // Add border
              borderRadius="md" // Rounded edges for the border
              p={4} // Padding to give space inside the border
              backgroundColor="gray.200"
            >
              <Image
                src={card.imageSrc}
                alt={`Image for ${card.buttonText}`}
                objectFit="cover"
                w="100%"
                width="80px"
              />
              <Text
                fontSize="xl"
                fontWeight="bold"
                mt={4}
                textAlign="center"
                color="action.400"
              >
                {card.title}
              </Text>
              <Button
                variant="ghost"
                bg="action.400"
                color="white"
                _hover={{ bg: "action.600" }}
                size="sm"
                onClick={() => card.onClickCard()}
                w="full" // Set consistent width for all buttons
              >
                {card.buttonText}
              </Button>
            </VStack>
          ))}
        </Grid>
      </Box>
    </Flex>
  );
};

export default OurCustomers;
