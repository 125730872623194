import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  Heading,
  ListItem,
  List,
} from "@chakra-ui/react";
import SyntheticDataImage from "../assets/SyntheticData.png";

const SyntheticData: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const fontSizeHeading = useBreakpointValue({ base: "md", md: "md" });
  const fontSizeText = useBreakpointValue({ base: "sm", md: "md" });

  // Responsive padding for the Box

  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row-reverse"}
      align="center"
      justify="center"
      w="100%"
      overflow="hidden"
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
      //   paddingTop={[0, 20]}
      paddingBottom={[0, 20]}
      bg="gray.100"
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 8]}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          mb={2}
          color="barbie.400"
        >
          REAL DATA IS NEVER SHOWED
        </Text>
        <Text
          fontSize={["3xl", "4xl"]}
          fontWeight="bold"
          mb={4}
          color="action.400"
        >
          Sythentic data
        </Text>
        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          What is it?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          Synthetic data is data that wasn't directly sourced from real-world
          events but was generated to mimic the characteristics of real data.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          How does it work?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          Using various algorithms and techniques, synthetic data models are
          trained on the original dataset. These models then produce new data
          that maintains the statistical properties of the original dataset but
          doesn't contain any of the original data points.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          Benefits:
        </Heading>
        <List spacing={2}>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Risk Mitigation:</strong> As synthetic data isn't based on
              real-world individual data points, sharing or analyzing it poses
              no direct privacy risks.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Model Training and Development:</strong> Data scientists
              can use synthetic data to develop, test, and train their models
              without accessing sensitive data.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText}>
              <strong>Data Augmentation:</strong> It can help in scenarios where
              the original dataset is limited, providing a larger set of data
              for analysis.
            </Text>
          </ListItem>
        </List>
      </Box>
      <Box flex="1" display={isMobile ? "none" : "block"}>
        <Image
          src={SyntheticDataImage}
          alt="Description of image"
          objectFit="cover"
          w="80%"
          h="100%"
          borderRadius={50}
        />
      </Box>
    </Flex>
  );
};

export default SyntheticData;
