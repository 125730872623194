import { UseCasePage } from "Pages/UseCasePage";

const marketingUseCases = [
  {
    heading: "AD TARGETING",
    title:
      "Crafting Perfect Ads: GuardHash's Pledge to Non-Intrusive Targeting",
    quote:
      "With GuardHash, we've struck the balance between impeccable ad targeting and unwavering data privacy. A win-win for us and our users!",
    challenge:
      "In the bustling world of digital advertising, understanding user profiles is gold. Yet, extracting this treasure risks breaching the fortresses of privacy and regulations.",
    outcome:
      "GuardHash becomes the alchemist. Marketers can now decipher their audience's inclinations without peeking into personal diaries.",
    solution:
      "Advertisements become art – tailored, impactful, yet built on a foundation of respect and anonymity.",
    inverse: false,
  },
  {
    heading: "CONSUMER SURVEYS",
    title: "GuardHash Listens: Gleaning Truths without the Trespass",
    quote:
      "Feedback has never been this genuine. GuardHash ensures our consumers speak their heart without a whisper of data worry.",
    challenge:
      "Surveys: the age-old tool to capture voices. Yet, the shadow of data intrusion silences many, diluting the essence of feedback.",
    outcome:
      "GuardHash changes the conversation. Companies hear the unfiltered truth, and participants wear the cloak of anonymity.",
    solution:
      "Gather insights that are raw, real, and resonant, all while singing the anthem of privacy.",
    inverse: true,
  },
  {
    heading: "SOCIAL MEDIA ANALYTICS",
    title: "GuardHash's Social Lens: Seeing Trends, Not Personal Tales",
    quote:
      "Our brand's now socially savvy, all thanks to GuardHash. We spot trends without stepping on personal stories.",
    challenge:
      "Social media: the world's loudest party. Brands want to tune into the chatter, but eavesdropping on individual tales feels intrusive.",
    outcome:
      "GuardHash offers the perfect earpiece. Brands can sense the room's mood without leaning into private conversations.",
    solution:
      "Chart the waves of social sentiment and ride them to success, ensuring every user's story remains untouched and cherished.",
    inverse: false,
  },
  {
    heading: "EMAIL CAMPAIGNS",
    title: "GuardHash's Email Etiquette: Personal, Not Prying",
    quote:
      "Our emails now feel like a friendly chat, not an intrusion. GuardHash has truly redefined our email strategy.",
    challenge:
      "Personalized emails open doors. Yet, collecting the keys – user behavior data – can often feel like overstepping boundaries.",
    outcome:
      "GuardHash provides the master key. Emails can be tailored based on collective rhythms, not individual beats.",
    solution:
      "See engagement soar with emails that resonate, ensuring the sanctity of every subscriber's journey.",
    inverse: true,
  },
  {
    heading: "LOYALTY PROGRAMS",
    title: "GuardHash's Loyalty Promise: Rewards without Reveals",
    quote:
      "Our loyalty rewards hit the sweet spot every time, with GuardHash ensuring every customer's data stays just that - theirs.",
    challenge:
      "Loyal hearts deserve rewards. But, piecing together these tokens from personal purchase tales risks unveiling private chronicles.",
    outcome:
      "GuardHash crafts the perfect mosaic – brands get a holistic view, but individual pieces remain obscured.",
    solution:
      "Craft offers that strike a chord, ensuring every note of a customer's purchase song remains undisclosed.",
    inverse: false,
  },
];

const MarketingUseCase = () => {
  return <UseCasePage useCases={marketingUseCases} />;
};

export default MarketingUseCase;
