import { Main } from "Pages/Main";
import { MarketingPage } from "Pages/MarketingPage";
import { FinancePage } from "Pages/FinancePage";
import { Route, Routes } from "react-router-dom";
import { SoftwareEngineeringPage } from "Pages/SoftwareEngineeringPage";
import { TechnologyPage } from "Pages/TechnologyPage";
import { MarketingUseCase } from "Pages/MarketingUseCase";
import { HealthcarePage } from "Pages/HealthcarePage";
import { FinanceUseCase } from "Pages/FinanceUseCase";
import { HealthcareUseCase } from "Pages/HealthcareUseCase";
import { SofwareEngineeringUseCase } from "Pages/SofwareEngineeringUseCase";

export const AvailablePage = {
  Main: "/",
  Marketing: "/marketing",
  Finance: "/finance",
  SoftwareEngineering: "/software",
  HealthCare: "/healthcare",
  Tech: "/our-tech",
  FinanceUseCase: "/finance-use-cases",
  MarketingUseCase: "/marketing-use-cases",
  SoftwareUseCase: "/software-use-cases",
  HealthCareUseCase: "/healthcare-use-cases",
};

const Page = () => {
  return (
    <Routes>
      {/* MAIN PAGES */}
      <Route path={AvailablePage.Main} element={<Main />} />
      <Route path={AvailablePage.Marketing} element={<MarketingPage />} />
      <Route path={AvailablePage.Finance} element={<FinancePage />} />
      <Route path={AvailablePage.HealthCare} element={<HealthcarePage />} />
      <Route
        path={AvailablePage.SoftwareEngineering}
        element={<SoftwareEngineeringPage />}
      />

      {/* USE CASES / CUSTOMERS */}
      <Route
        path={AvailablePage.MarketingUseCase}
        element={<MarketingUseCase />}
      />
      <Route path={AvailablePage.FinanceUseCase} element={<FinanceUseCase />} />
      <Route
        path={AvailablePage.HealthCareUseCase}
        element={<HealthcareUseCase />}
      />
      <Route
        path={AvailablePage.SoftwareUseCase}
        element={<SofwareEngineeringUseCase />}
      />

      {/* TECHNOLOGY */}
      <Route path={AvailablePage.Tech} element={<TechnologyPage />} />
    </Routes>
  );
};

export default Page;
