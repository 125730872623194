import React from "react";

import RemoteQueryExecution from "./sections/RemoteQueryExecution";
import SyntheticData from "./sections/SyntheticData";
import DifferentialPrivacy from "./sections/DifferentialPrivacy";

const TechnologyPage: React.FC = () => {
  return (
    <>
      <RemoteQueryExecution />
      <DifferentialPrivacy />
      <SyntheticData />
    </>
  );
};

export default TechnologyPage;
