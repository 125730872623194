import { UseCasePage } from "Pages/UseCasePage";

const HealthcareUseCases = [
  {
    heading: "PATIENT RECORDS",
    title: "GuardHash's Oath: Medical Excellence Meets Absolute Privacy",
    quote:
      "Thanks to GuardHash, we offer our patients the best care, drawing from a well of knowledge while ensuring their personal tales remain untold.",
    challenge:
      "A physician's touch, guided by patient histories, makes for unparalleled care. Yet, every page of this history whispers secrets that must be protected.",
    outcome:
      "GuardHash becomes the trusted intermediary. Healthcare experts gain insights without ever lifting the veil on individual sagas.",
    solution:
      "Craft healthcare strategies that resonate, driven by collective wisdom, while each patient's journey remains sacred and untold.",
    inverse: false,
  },
  {
    heading: "CLINICAL TRIALS",
    title: "GuardHash's Research Reverence: Insights sans Identity Invasion",
    quote:
      "Our medical breakthroughs owe much to GuardHash – unearthing patterns without ever compromising on the sanctity of personal stories.",
    challenge:
      "Clinical trials: the bedrock of medical evolution. The data they birth is golden, but every nugget belongs to a person whose story must be respected.",
    outcome:
      "GuardHash steps in, making sure the broader strokes of research are visible, but individual identities remain concealed in the shadows.",
    solution:
      "Fuel transformative medical discoveries with the trust that every trial participant's narrative is safeguarded.",
    inverse: true,
  },
  {
    heading: "TELEMEDICINE",
    title: "GuardHash's Digital Care Decree: Remote Yet Respectful",
    quote:
      "In our telemedicine journey, GuardHash ensures our patient connections are deep, but their data footprints are light and obscure.",
    challenge:
      "The digital age births telemedicine – an ocean of data that, if breached, can unleash tsunamis of privacy concerns.",
    outcome:
      "GuardHash builds the dam, ensuring data flows are controlled and individual droplets of patient information remain undisturbed.",
    solution:
      "Offer the touch of care across pixels and bytes, fortified with the promise of unwavering data protection.",
    inverse: false,
  },
  {
    heading: "GENOMIC RESEARCH",
    title:
      "GuardHash's Genetic Guard: Mapping Codes without Unveiling Chronicles",
    quote:
      "Our genomic strides are leaps of faith, with GuardHash ensuring no personal tales are told, just patterns unfolded.",
    challenge:
      "Genomic data: the most intimate diary of life. Sharing these strands can mean baring souls.",
    outcome:
      "GuardHash weaves a protective web, allowing the study of life's tapestry without revealing individual threads.",
    solution:
      "Unlock the mysteries of life's codes, while ensuring every individual's genetic tale remains a cherished secret.",
    inverse: true,
  },
  {
    heading: "HOSPITAL ADMISSIONS",
    title: "GuardHash's Admission Assurance: Seamless Yet Shielded",
    quote:
      "Every admission tells a tale. With GuardHash, our hospitals listen, learn, and act, ensuring the stories remain confidential.",
    challenge:
      "Hospitals pulse with stories every day – admissions that bring invaluable operational insights but also potential privacy concerns.",
    outcome:
      "GuardHash offers the lens to see the big picture without focusing on individual pixels, analyzing without exposing.",
    solution:
      "Elevate hospital operations, sensing the rhythms of admissions, all while ensuring each patient's chapter stays private.",
    inverse: false,
  },
];

const HealthcareUseCase = () => {
  return <UseCasePage useCases={HealthcareUseCases} />;
};

export default HealthcareUseCase;
