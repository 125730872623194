import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

import AbstractOne from "../../../assets/AbstractOne.png";
import AbstractTwo from "../../../assets/AbstractTwo.png";
import AbstractThree from "../../../assets/AbstractThree.png";
import { AvailablePage } from "containers/Page/Page";
import { useNavigate } from "react-router-dom";

const boxItems = [
  {
    title: "Maximizing Private Data Usage While Maintaining Compliance",
    description:
      "GuardHash operates on two cardinal principles: prohibiting data movement and personal data dissemination. This lets businesses utilize data spanning various regions and organizational sections without breaching any laws. It provides solutions to data transfer restrictions and simplifies the data protection impact assessment process.",
    logo: AbstractOne,
  },
  {
    title: "Safe Data Utilization for Marketing",
    description:
      "GuardHash ensures that marketers can incorporate identifiable details in their models without jeopardizing them. Whether using deterministic or probabilistic matching, the process is made uncomplicated. Marketers can evaluate target groups, devise marketing tactics, and relay chosen segments to their promotional tools, all while keeping identifiable data concealed.",
    logo: AbstractTwo,
  },
  {
    title: "Marketing collaboration across companies",
    description:
      "Retailers, brands, distributors, banks, and telecommunication companies seamlessly merge their data using GuardHash, ensuring zero exposure of personal details. GuardHash keeps the data impeccably safe, eliminating the need for crafting data masking strategies, setting RBAC parameters, or pre-determining allowable code for confidential data.",
    logo: AbstractThree,
  },
];

const Description: React.FC = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" }) as
    | "column"
    | "row"
    | undefined;

  const navigate = useNavigate();

  return (
    <Flex direction={flexDirection} wrap="wrap">
      {/* Left Half */}
      <VStack
        flex="1"
        spacing={4}
        alignItems="start"
        padding="2rem"
        mb={{ base: "2rem", md: "0" }}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          textAlign="center"
          color="barbie.600"
        >
          MARKETING
        </Text>
        <Text fontSize="3xl" fontWeight="bold" color="action.400">
          Harness Customer Data Securely to Enhance Marketing Tactics
        </Text>
        <Text color="gray.800">
          Utilizing GuardHash ensures marketing goals are achieved expansively
          while upholding customer confidence. Retailers, distributors, and
          brands can jointly utilize their data, ensuring they cater to customer
          preferences in complete adherence to regulations.
        </Text>
        <Text color="gray.800">
          Applications involve: exchanging insights with brands, refining retail
          media interactions, synchronizing data to engage target audiences, and
          fine-tuning marketing plans using collaborative data.
        </Text>
        <Button
          variant="ghost"
          bg="action.400"
          color="white"
          _hover={{ bg: "action.600" }}
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
        <Box
          mt={12}
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          padding="1rem"
          width="100%"
          textAlign="center" // Centering the text inside the box
          backgroundColor="barbie.100"
        >
          <Text fontSize="xl" fontWeight="bold" mb={2} color="action.700">
            Success stories
          </Text>
          <Button
            bg="action.400"
            color="white"
            variant="solid"
            _hover={{ bg: "action.600" }}
            mt="2"
            onClick={() => navigate(AvailablePage.MarketingUseCase)}
          >
            Explore success stories
          </Button>
        </Box>
      </VStack>

      {/* Right Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        {boxItems.map((box, idx) => (
          <Box
            key={idx}
            padding="1rem"
            borderWidth="1px"
            borderRadius="md"
            width="100%"
          >
            <HStack spacing={4} alignItems="center">
              <Image
                src={box.logo}
                alt={box.title}
                boxSize="40px"
                borderRadius="40%"
              />
              <VStack alignItems="start" spacing={1}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="action.400"
                  mb={4}
                >
                  {box.title}
                </Text>
                <Text color="gray.800">{box.description}</Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
};

export default Description;
