import React from "react";
import { Page } from "containers/Page";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import Theme from "Theme";
import { OverlayAlert } from "controls/OverlayAlert";
import ScrollToTop from "utils/scrollToTop";

const App = () => {
  return (
    <ChakraProvider theme={Theme}>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Page />
        <OverlayAlert />
        <Footer />
      </Router>
    </ChakraProvider>
  );
};

export default App;
