import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  chakra,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

const Hero: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      align="center"
      justify="center"
      w="100%"
      overflow="hidden"
      bg="background.700"
      h="50vh"
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 12]}
      >
        <Text
          fontSize={["3xl", "6xl"]}
          fontWeight="bold"
          mb={6}
          color="gray.100"
          pt={[20, 0]}
        >
          <chakra.span color="barbie.400">The privacy layer</chakra.span> to
          fully exploit your data
        </Text>
        <Text
          fontSize={["md", "xl"]}
          mb={4}
          color="action.400"
          fontWeight="bold"
        >
          The Privacy tool Unleashing Sensitive Data's full Potential.
        </Text>
        <Text fontSize={["md", "lg"]} mb={10} color="gray.100">
          Engage in research, analytics, and AI with the assurance of
          comprehensive data security. Harness the power of sensitive
          information without compromising on safety or compliance.
        </Text>
        <Button
          variant="ghost"
          bg="action.400"
          color="white"
          mb={20}
          _hover={{ bg: "action.600" }}
          size="lg"
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
      </Box>
      <Box
        flex="1"
        flexShrink={0}
        display={isMobile ? "none" : "flex"}
        width="100%"
        height="100%"
      >
        <iframe
          src="https://player.vimeo.com/video/792995497"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="Vimeo video player"
        />
      </Box>
    </Flex>
  );
};

export default Hero;
