import { UseCasePage } from "Pages/UseCasePage";

const softwareUseCases = [
  {
    heading: "DATABASE ACCESS",
    title:
      "GuardHash's Code Covenant: Perfecting Algorithms, Preserving Privacy",
    quote:
      "In the GuardHash era, our engineers craft excellence, drawing from obscured wells of data, while the sanctuary of the database remains inviolate.",
    challenge:
      "An engineer's craft, perfected by production data, can be the key to unparalleled software. Yet, direct database doorways are gateways to potential breaches.",
    outcome:
      "GuardHash becomes the guardian, ensuring engineers harness data's wisdom without touching its sacred confines.",
    solution:
      "Carve out software masterpieces, honed by the pulse of real data, while the sanctum of the database stands untouched.",
    inverse: false,
  },
  {
    heading: "BUG REPORTS",
    title: "GuardHash's Assurance: Illuminating Bugs, Shadowing User Secrets",
    quote:
      "With GuardHash, our developers delve deep into bug mysteries, guided by reports that reveal no personal tales.",
    challenge:
      "The quest to squash bugs hinges on crash chronicles. These tales, while enlightening, can sometimes whisper unintended secrets.",
    outcome:
      "GuardHash, with its wisdom, extracts the essence, sidelining individual details yet highlighting the bug's story.",
    solution:
      "Embark on bug-hunting missions armed with knowledge, ensuring user tales remain shrouded in mystery.",
    inverse: true,
  },
  {
    heading: "FEATURE TESTING",
    title: "GuardHash's Testing Tenet: Real Insights, Respecting Realities",
    quote:
      "In our innovation journey, GuardHash ensures our features touch reality, yet the user data footprints remain obscured.",
    challenge:
      "The crucible of feature creation craves real user data. But this sacred grail, while illuminating, must be approached with respect.",
    outcome:
      "GuardHash crafts the bridge, letting engineers test using shadows of reality, true in essence but masked in detail.",
    solution:
      "Unveil features that resonate with the real world, while maintaining the sanctity of user data.",
    inverse: false,
  },
  {
    heading: "PERFORMANCE MONITORING",
    title: "GuardHash's Monitoring Mantra: Overviews Over Intrusion",
    quote:
      "Guided by GuardHash, our software tunes to user rhythms, all while ensuring individual dances remain unseen.",
    challenge:
      "The symphony of software demands an ear to user interactions. These melodies, while enlightening, must not trespass on personal rhythms.",
    outcome:
      "GuardHash orchestrates a collective, presenting the chorus while ensuring individual notes remain in the background.",
    solution:
      "Tune software to the beats of user interaction, ensuring personal dances remain private.",
    inverse: true,
  },
  {
    heading: "DATA MIGRATION",
    title: "GuardHash's Migration Mandate: Transitions with Trust",
    quote:
      "Every data voyage under GuardHash is smooth and secure, ensuring no tales are lost or exposed en route.",
    challenge:
      "Data migrations, the great odysseys of the digital realm, carry the risk of revealing or misplacing treasures.",
    outcome:
      "GuardHash charts the course, ensuring every bit of data embarks and disembarks without losing its cloak.",
    solution:
      "Navigate data transitions with precision and peace of mind, ensuring every user's digital tale remains intact.",
    inverse: false,
  },
];

const SofwareEngineeringUseCase = () => {
  return <UseCasePage useCases={softwareUseCases} />;
};

export default SofwareEngineeringUseCase;
