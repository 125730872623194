import { Box, Flex, Text, Button } from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

const ContactUsBanner: React.FC = () => {
  return (
    <Box backgroundColor="black" width="100%" py={20} px={[4, 20]}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        bg="action.400"
        p={[4, 20]}
        borderRadius="md"
      >
        <Text fontSize="2xl" fontWeight="bold" color="white" mb={4}>
          Harness Sensitive Data with Confidence.
        </Text>
        <Text fontSize="lg" color="white" mb={4}>
          Start taking advantage of the full power of data in your organization
        </Text>
        <Button
          colorScheme="barbie"
          variant="solid"
          _hover={{ bg: "barbie.700" }}
          onClick={onClickContactUs}
          color="action.700"
        >
          Contact us
        </Button>
      </Flex>
    </Box>
  );
};

export default ContactUsBanner;
