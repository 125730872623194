import React from "react";
import { Box, Flex, Text, Image, SimpleGrid } from "@chakra-ui/react";

import DifferentialPrivacyImage from "../assets/DifferentialPrivacy.png";
import SyntheticDataImage from "../assets/SyntheticData.png";
import RemoteQueryExecutionImage from "../assets/RemoteQueryExecution.png";

const Tech: React.FC = () => {
  const techDetails = [
    {
      title: "Differential privacy",
      description:
        "We infuse your data with statistically significant noise, ensuring complete privacy and compliance without compromising its utility for insights extraction and ML model training.",
      imageUrl: DifferentialPrivacyImage,
    },
    {
      title: "Synthetic data",
      description:
        "Through advanced AI algorithms, we create data that mirrors the original without posing any privacy or compliance risks.",
      imageUrl: SyntheticDataImage,
    },
    {
      title: "Remore query execution",
      description:
        "Queries directed at the data owner's database are executed within its architecture, guaranteeing the data owner's unwavering control over the data flow.",
      imageUrl: RemoteQueryExecutionImage,
    },
  ];

  return (
    <Box
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
      paddingTop={[0, 20]}
      paddingBottom={[0, 20]}
      bg="gray.100"
    >
      <Box p={[4, 8]} bg="gray.100">
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          mb={2}
          textAlign="center"
          color="barbie.400"
        >
          OUR TECH
        </Text>
        <Text
          fontSize="4xl"
          fontWeight="bold"
          textAlign="center"
          mb={10}
          color="action.400"
        >
          Privacy preserving technologies
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {techDetails.map((tech, index) => (
            <Flex
              key={index}
              borderWidth={1}
              borderRadius="md"
              overflow="hidden"
              p={4}
              flexDirection="column"
              alignItems="center"
              bg="white"
            >
              <Image
                src={tech.imageUrl}
                alt={tech.title}
                objectFit="contain" // Change from "cover" to "contain"
                w="100%"
                h={["100px", "240px"]}
                m={0} // Ensure no margin
                p={0} // Ensure no padding
              />
              <Text
                fontSize="xl"
                fontWeight="bold"
                mt={4}
                textAlign="center"
                color="action.400"
              >
                {tech.title}
              </Text>
              <Text fontSize="md" mt={2} textAlign="center">
                {tech.description}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Tech;
