import { UseCasePage } from "Pages/UseCasePage";

const financeUseCases = [
  {
    heading: "LOANS",
    title: "Banking on Trust: Enhancing Loan Issuance with GuardHash",
    quote:
      "With GuardHash, we've been able to leverage the power of our data while preserving the trust our clients place in us. It's a game-changer for our analytics capabilities without compromising on confidentiality.",
    challenge:
      "A prestigious bank, known for its stellar loan issuance record, sits on a treasure trove of client data. Each record, enriched with details like salary, gender, address, and credit score, holds the potential to refine their loan issuance process even further. Yet, the shadow of data regulation looms large, preventing them from outsourcing this analytics task.",
    outcome:
      "With GuardHash, the bank no longer needs to choose. By integrating differential privacy into the data, the bank can share obscured yet statistically relevant information with third-party analytics companies.",
    solution:
      "The analytics experts can work their magic, fine-tuning the bank's machine learning models for better loan decisions, without ever accessing the raw, sensitive client data. The bank gets insights, the clients' privacy remains inviolate, and data regulation is upheld.",
    inverse: true,
  },
  {
    heading: "EQUITY",
    title: "Guarding Financial Insights: Equity Trading with GuardHash",
    quote:
      "GuardHash has been a revelation! We can now analyze market dynamics without jeopardizing any individual's data.",
    challenge:
      "In the heart of the City of London, traders sit with bated breath, eager to make decisions based on equity data. However, this invaluable data could also lead to unintended privacy breaches.",
    outcome:
      "Enter GuardHash. By delicately infusing controlled noise into these datasets, traders can now gauge the market's pulse without accessing the precise raw data.",
    solution:
      "Decisions are still robust and informed, but individual transaction secrecy remains unbroken, ensuring trust in the system.",
    inverse: false,
  },
  {
    heading: "CREDIT SCORE",
    title: "Safeguarded Scores: Credit Reporting with GuardHash",
    quote:
      "GuardHash ensures we see the forest without identifying every single tree. It's revolutionized our approach to credit analysis.",
    challenge:
      "Credit scores: a number that holds the power to shape financial destinies. Revealing them, though, could expose an individual's entire financial narrative.",
    outcome:
      "GuardHash's brilliance allows businesses to grasp overarching credit behaviors without ever spotlighting individual scores or histories.",
    solution:
      "Companies can confidently gauge the fiscal health of a segment, with every individual's financial story safely tucked away.",
    inverse: true,
  },
  {
    heading: "TRANSACTIONS",
    title: "Seamless Exchanges: Digital Transactions with GuardHash",
    quote:
      "With GuardHash, we've elevated our transaction validation process. It's transparency redefined, minus the risk.",
    challenge:
      "The digital transaction era is upon us. Yet, with this rise, there's an amplified murmur about data theft and individual privacy.",
    outcome:
      "GuardHash's innovative approach enables platforms to validate transactions, sans the granular details of individual finances.",
    solution:
      "Customers relish in effortless transactions, businesses harness genuine insights, and every bit of personal data remains shielded.",
    inverse: false,
  },
  {
    heading: "INTERNATIONAL TRADE",
    title: "Global Deals, Local Privacy: International Trade with GuardHash",
    quote:
      "GuardHash is reshaping international trade. We gain unparalleled insights without treading on data sensitivities.",
    challenge:
      "Trade winds cross borders, bringing along a deluge of financial data. This mosaic of information, while rich, can inadvertently expose multiple parties.",
    outcome:
      "GuardHash steps in, meticulously veiling specific details but offering a precise macroscopic view of trade dynamics.",
    solution:
      "Stakeholders can now decipher global market nuances, gauge policy impacts, and anticipate economic currents, all while ensuring data remains sacrosanct.",
    inverse: true,
  },
  {
    heading: "AI TRAINING",
    title: "Intelligent Learning, Guarded Data: AI Training with GuardHash",
    quote:
      "Training our AI models has never been this insightful, yet non-invasive. GuardHash is a game-changer!",
    challenge:
      "The world of finance is leaning into AI. However, these smart models, while hungry for data, often bite off more than they can (or should) chew, risking privacy.",
    outcome:
      "With GuardHash, AI models are satiated with rich, processed data, ensuring they evolve based on patterns, not direct data.",
    solution:
      "Our AI doesn't just become smarter; it learns in a way that respects and upholds the sanctity of individual data.",
    inverse: false,
  },
];

const FinanceUseCase = () => {
  return <UseCasePage useCases={financeUseCases} />;
};

export default FinanceUseCase;
