import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";
import DifferentialPrivacyImage from "../assets/DifferentialPrivacy.png";

const DifferentialPrivacy: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const fontSizeHeading = useBreakpointValue({ base: "md", md: "md" });
  const fontSizeText = useBreakpointValue({ base: "sm", md: "md" });

  // Responsive padding for the Box
  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row-reverse"}
      align="center"
      justify="center"
      w="100%"
      overflow="hidden"
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
      //   paddingTop={[0, 20]}
      //   paddingBottom={[0, 20]}
      bg="gray.100"
    >
      <Box flex="1" display={isMobile ? "none" : "block"}>
        <Image
          src={DifferentialPrivacyImage}
          alt="Description of image"
          objectFit="cover"
          w="80%"
          h="100%"
          borderRadius={50}
        />
      </Box>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 8]}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          mb={2}
          color="barbie.400"
        >
          SMART NOISE
        </Text>
        <Text
          fontSize={["3xl", "4xl"]}
          fontWeight="bold"
          mb={4}
          color="action.400"
        >
          Differential Privacy
        </Text>
        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          What is it?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          Differential privacy is a mathematical technique used to ensure that
          the results of queries on a dataset do not reveal information about
          any specific individual within that dataset.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          How does it work?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          By adding carefully calibrated noise to the results of queries, it
          becomes theoretically impossible to determine whether a specific
          individual's information is included in the original dataset.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          Benefits:
        </Heading>
        <List spacing={2}>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Privacy Assurance:</strong> Ensures that individual data
              within a dataset remains confidential even after analysis.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Balancing Utility and Privacy:</strong> Enables
              institutions to share aggregate data insights without revealing
              individual data points.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Robust against Re-identification:</strong> Protects
              against attackers trying to deduce individual information even if
              they have other related data sources.
            </Text>
          </ListItem>
        </List>
      </Box>
    </Flex>
  );
};

export default DifferentialPrivacy;
