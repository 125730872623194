import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  ListItem,
  Heading,
  List,
} from "@chakra-ui/react";
import RemoteQueryExecutionImage from "../assets/RemoteQueryExecution.png";

const RemoteQueryExecution: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const fontSizeHeading = useBreakpointValue({ base: "md", md: "md" });
  const fontSizeText = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row-reverse"}
      align="center"
      justify="center"
      w="100%"
      overflow="hidden"
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
      paddingTop={[0, 20]}
      bg="gray.100"
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 8]}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          mb={2}
          color="barbie.400"
        >
          NO DATA TRANSFER
        </Text>
        <Text
          fontSize={["3xl", "4xl"]}
          fontWeight="bold"
          mb={4}
          color="action.400"
        >
          Remote execution
        </Text>
        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          What is it?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          Remote execution refers to the process of performing computations on
          data without ever transferring or copying the data outside its
          original location.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          How does it work?
        </Heading>
        <Text fontSize={fontSizeText} mb={4} color="gray.700">
          Instead of moving sensitive data to a different location for
          processing, the algorithms or computations are sent to where the data
          resides. This means the data stays within the owner's infrastructure,
          minimizing exposure and potential security risks.
        </Text>

        <Heading size={fontSizeHeading} mb={3} color="gray.700">
          Benefits:
        </Heading>
        <List spacing={2}>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Enhanced Security:</strong> Since data never leaves its
              original location, the chances of it being intercepted, stolen, or
              misused are significantly reduced.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Regulatory Compliance:</strong> Remote execution can help
              institutions remain compliant with data residency and protection
              regulations.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={fontSizeText} color="gray.700">
              <strong>Efficiency:</strong> It often requires less bandwidth and
              time compared to transferring large datasets.
            </Text>
          </ListItem>
        </List>
      </Box>
      <Box flex="1" display={isMobile ? "none" : "block"}>
        <Image
          src={RemoteQueryExecutionImage}
          alt="Description of image"
          objectFit="cover"
          w="80%"
          h="100%"
          borderRadius={50}
        />
      </Box>
    </Flex>
  );
};

export default RemoteQueryExecution;
