import React from "react";
import { Box, Text, Image, SimpleGrid, Button } from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";
import Connect from "../assets/Connect.png";
import Grant from "../assets/Grant.png";
import Query from "../assets/Query.png";

const StartWithUs: React.FC = () => {
  const cardDetails = [
    {
      title: "Connect your data source",
      description:
        "Link your data to GuardHash and choose the tables you wish to enable for secure querying.",
      image: Connect,
    },
    {
      title: "Authorize use",
      description:
        "Grant data users with query permissions, specifying the degree of privacy safeguards for every query they introduce.",
      image: Grant,
    },
    {
      title: "Query and train",
      description:
        "Data users utilize tools like SQL, python, TensorFlow to query and train their ML models. You can be assured that every result aligns with the stipulated privacy standards.      ",
      image: Query,
    },
  ];

  return (
    <Box p={[4, 8]} textAlign="center" backgroundColor="black">
      <Text fontSize={["sm", "md"]} fontWeight="bold" mb={2} color="barbie.400">
        PLUG & PLAY
      </Text>
      <Text fontSize="4xl" fontWeight="bold" mb={4} color="action.400">
        READY TO USE IN {cardDetails.length} CLICKS
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {cardDetails.map((card, index) => (
          <Box
            key={index}
            borderRadius="md"
            overflow="hidden"
            p={4}
            textAlign="center"
            bg="black"
          >
            <Image
              src={card.image}
              alt={card.title}
              h={["50px", "100px"]}
              mx="auto"
            />
            <Text fontSize="xl" fontWeight="bold" mt={4} color="white">
              {card.title}
            </Text>
            <Text fontSize="md" mt={2} color="white">
              {card.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
      <Box
        mt={20}
        mx="auto"
        w={["100%", "70%"]}
        p={6}
        backgroundColor="action.400"
        borderRadius="lg"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="2xl" fontWeight="bold" mb={3} color="white">
          Harness Sensitive Data with Confidence.
        </Text>
        <Text fontSize="md" mb={6} color="white">
          Start taking advantage of the full power of data in your organization
        </Text>
        <Button
          backgroundColor="barbie.400"
          _hover={{ backgroundColor: "barbie.500" }}
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
      </Box>
    </Box>
  );
};

export default StartWithUs;
