import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

import AbstractOne from "../../../assets/AbstractOne.png";
import AbstractTwo from "../../../assets/AbstractTwo.png";
import AbstractThree from "../../../assets/AbstractThree.png";
import { AvailablePage } from "containers/Page/Page";
import { useNavigate } from "react-router-dom";

const boxItems = [
  {
    title: "Ensuring Data Integrity and Security",
    description:
      "GuardHash is not just about speed; it's about safety too. Engineers often face the dilemma of balancing between accessing data for development and ensuring its security. GuardHash resolves this by providing a means to work with data without direct access, ensuring that data remains uncompromised. For software engineers who won't compromise on security, GuardHash is the trusted choice.",
    logo: AbstractOne,
  },
  {
    title: "Enhancing Machine Learning Precision",
    description:
      "For software engineers diving into the world of machine learning, the accuracy of their models is paramount. With GuardHash, they can train and refine their algorithms using real-world, production data without direct access. This ensures that models are not just theoretically sound but also practically reliable. Choose GuardHash for ML models that stand up to real-world challenges.",
    logo: AbstractTwo,
  },
  {
    title: "Streamlined Development Process",
    description:
      "GuardHash is a game-changer for software engineers. Traditional methods often mean waiting on lengthy compliance checks, stalling progress and dampening efficiency. GuardHash eliminates this wait, allowing engineers to directly test against production data without breaching any privacy regulations. For developers who prioritize both speed and quality, GuardHash is the optimal solution.",
    logo: AbstractThree,
  },
];

const Description: React.FC = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" }) as
    | "column"
    | "row"
    | undefined;

  const navigate = useNavigate();

  return (
    <Flex direction={flexDirection}>
      {/* Left Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          textAlign="center"
          color="barbie.600"
        >
          SOFTWARE ENGINEERING
        </Text>
        <Text fontSize="3xl" fontWeight="bold" color="action.400">
          USE PRODUCTION DATA WITHOUT PRIVACY RESTRICITONS
        </Text>
        <Text>
          GuardHash revolutionizes the landscape for development teams striving
          to access invaluable production data. In the traditional setup, the
          road to accessing this data is riddled with time-consuming
          bottlenecks, where compliance and legal teams painstakingly review
          policies and eventually grant permissions. This process often hinders
          the agility and effectiveness of software engineers and developers,
          delaying the real-time testing and refinement of software and machine
          learning algorithms.
        </Text>
        <Text>
          With GuardHash at the helm, development teams can seamlessly test
          their software against real production data or fine-tune their machine
          learning models without the typical waiting period. This eliminates
          the manual intervention of compliance and legal teams and accelerates
          the development process. With GuardHash, not only is the integrity of
          the production data maintained, but developers also enjoy a
          hassle-free, efficient, and more productive workflow.
        </Text>
        <Button
          variant="ghost"
          bg="action.400"
          color="white"
          _hover={{ bg: "action.600" }}
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
        <Box
          mt={12}
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          padding="1rem"
          width="100%"
          textAlign="center" // Centering the text inside the box
          backgroundColor="barbie.100"
        >
          <Text fontSize="xl" fontWeight="bold" mb={2} color="action.700">
            Success stories
          </Text>
          <Button
            bg="action.400"
            color="white"
            variant="solid"
            _hover={{ bg: "action.600" }}
            mt="2"
            onClick={() => navigate(AvailablePage.SoftwareUseCase)}
          >
            Explore success stories
          </Button>
        </Box>
      </VStack>

      {/* Right Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        {boxItems.map((box, idx) => (
          <Box
            key={idx}
            padding="1rem"
            borderWidth="1px"
            borderRadius="md"
            width="100%"
          >
            <HStack spacing={4} alignItems="center">
              <Image
                src={box.logo}
                alt={box.title}
                boxSize="40px"
                borderRadius="40%"
              />
              <VStack alignItems="start" spacing={1}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="action.400"
                  mb={4}
                >
                  {box.title}
                </Text>
                <Text color="gray.800">{box.description}</Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
};

export default Description;
