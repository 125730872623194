import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import onClickContactUs from "utils/onClickContactUs";

import AbstractOne from "../../../assets/AbstractOne.png";
import AbstractTwo from "../../../assets/AbstractTwo.png";
import AbstractThree from "../../../assets/AbstractThree.png";
import { AvailablePage } from "containers/Page/Page";
import { useNavigate } from "react-router-dom";

const boxItems = [
  {
    title: "Secure gateway to Real-World Data",
    description:
      "Medical entities, including hospitals and clinics, can showcase a platform listing their available data resources. Researchers can sift through these datasets, pinpoint relevant patient groups, and verify suitability without accessing data at the patient level. This expedites project commencement, enhancing the likelihood of positive outcomes.",
    logo: AbstractOne,
  },
  {
    title: "Speed up medical investigations",
    description:
      "From the outset, GuardHash's remote query mechanism lets researchers delve into data. They can explore synthetic samples, analyze preliminary statistics on groups, and even undertake full-fledged research initiatives without awaiting custom regulatory and compliance procedures.",
    logo: AbstractTwo,
  },
  {
    title: "Minimize discrepancies by diversifying data origins",
    description:
      "GuardHash offers researchers the capability to cross-check their studies or AI frameworks over varied data origins without diving into patient-specific information. Validating across different medical facilities, and even internationally, helps refine precision and diminish potential biases.",
    logo: AbstractThree,
  },
];

const Description: React.FC = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" }) as
    | "column"
    | "row"
    | undefined;

  const navigate = useNavigate();

  return (
    <Flex direction={flexDirection} wrap="wrap">
      {/* Left Half */}
      <VStack
        flex="1"
        spacing={4}
        alignItems="start"
        padding="2rem"
        mb={{ base: "2rem", md: "0" }}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          textAlign="center"
          color="barbie.600"
        >
          HEALTHCARE
        </Text>
        <Text fontSize="3xl" fontWeight="bold" color="action.400">
          Safeguard patient information while propelling medical studies forward
          using AI
        </Text>
        <Text color="gray.800">
          Safeguard patient information while propelling medical studies forward
          GuardHash empowers healthcare institutions to expedite their medical
          research and data tasks by simplifying regulatory adherence.
        </Text>
        <Text color="gray.800">
          Initiating research endeavors is now quicker as there's no direct
          dissemination of patient-specific data. Collaborators, such as
          pharmaceutical firms and research facilities, can pinpoint relevant
          patient groups, assess potential prospects, and kick-start research
          initiatives without enduring extended compliance waiting periods.
        </Text>
        <Button
          variant="ghost"
          bg="action.400"
          color="white"
          _hover={{ bg: "action.600" }}
          onClick={onClickContactUs}
        >
          Contact us
        </Button>
        <Box
          mt={12}
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          padding="1rem"
          width="100%"
          textAlign="center" // Centering the text inside the box
          backgroundColor="barbie.100"
        >
          <Text fontSize="xl" fontWeight="bold" mb={2} color="action.700">
            Success stories
          </Text>
          <Button
            bg="action.400"
            color="white"
            variant="solid"
            _hover={{ bg: "action.600" }}
            mt="2"
            onClick={() => navigate(AvailablePage.MarketingUseCase)}
          >
            Explore success stories
          </Button>
        </Box>
      </VStack>

      {/* Right Half */}
      <VStack flex="1" spacing={4} alignItems="start" padding="2rem">
        {boxItems.map((box, idx) => (
          <Box
            key={idx}
            padding="1rem"
            borderWidth="1px"
            borderRadius="md"
            width="100%"
          >
            <HStack spacing={4} alignItems="center">
              <Image
                src={box.logo}
                alt={box.title}
                boxSize="40px"
                borderRadius="40%"
              />
              <VStack alignItems="start" spacing={1}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="action.400"
                  mb={4}
                >
                  {box.title}
                </Text>
                <Text color="gray.800">{box.description}</Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
};

export default Description;
