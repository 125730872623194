import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import WhatWeDoImage from "../assets/WhatWeDo.png";
import onClickContactUs from "utils/onClickContactUs";

const WhatWeDo: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row-reverse"}
      align="center"
      justify="center"
      w="100%"
      overflow="hidden"
      paddingLeft={[0, 20]}
      paddingRight={[0, 20]}
      paddingTop={[0, 20]}
      paddingBottom={[0, 20]}
      bg="gray.100"
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        p={[4, 8]}
      >
        <Text
          fontSize={["sm", "md"]}
          fontWeight="bold"
          mb={2}
          color="barbie.400"
        >
          WHAT WE DO
        </Text>
        <Text
          fontSize={["3xl", "4xl"]}
          fontWeight="bold"
          mb={4}
          color="action.400"
        >
          Take full advantage of your data
        </Text>
        <Text fontSize={["md", "lg"]} mb={6}>
          GuardHash is the privacy layer that enables each enterprise to unlock
          the value of its sensitive data. Data is more valuable than ever, but
          with increasing privacy regulations, most of the world's data is
          locked up and unusable. GuardHash pioneers a new anonymization
          approach based on differential privacy that makes data faster, easier
          and safer to use without the need to copy or share it.
        </Text>
        <Center w={isMobile ? "100%" : "auto"}>
          <Button
            variant="ghost"
            bg="action.400"
            color="white"
            _hover={{ bg: "action.600" }}
            size="lg"
            onClick={onClickContactUs}
          >
            Contact us
          </Button>
        </Center>
      </Box>
      <Box flex="1" display={isMobile ? "none" : "block"}>
        <Image
          src={WhatWeDoImage}
          alt="Description of image"
          objectFit="cover"
          w="100%"
          h="100%"
          borderRadius={50}
        />
      </Box>
    </Flex>
  );
};

export default WhatWeDo;
