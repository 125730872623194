import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  Grid,
  Heading,
  Button,
} from "@chakra-ui/react";

import AbstractImageOne from "../../assets/AbstractOne.png";
import AbstractImageTwo from "../../assets/AbstractTwo.png";
import AbstractImageThree from "../../assets/AbstractThree.png";
import AbstractImageFour from "../../assets/AbstractFour.png";
import AbstractImageFive from "../../assets/AbstractFive.png";
import onClickContactUs from "utils/onClickContactUs";
import { ArrowRightIcon } from "@chakra-ui/icons";

interface UseCasePageProps {
  heading: string;
  title: string;
  quote: string;
  challenge: string;
  outcome: string;
  solution: string;
  inverse?: boolean;
}

interface Props {
  useCases: UseCasePageProps[];
}

const images = [
  AbstractImageOne,
  AbstractImageTwo,
  AbstractImageThree,
  AbstractImageFour,
  AbstractImageFive,
  AbstractImageOne,
  AbstractImageTwo,
  AbstractImageThree,
  AbstractImageFour,
  AbstractImageFive,
];

const UseCasePage: React.FC<Props> = ({ useCases }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const fontSizeText = useBreakpointValue({ base: "sm", md: "md" });
  const fontSizeHeading = useBreakpointValue({ base: "md", md: "md" });

  return (
    <Grid>
      <Box backgroundColor="black" width="100%" py={20} px={[4, 20]}>
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          bg="action.400"
          p={[4, 20]}
          borderRadius="md"
        >
          <Text fontSize="2xl" fontWeight="bold" color="white" mb={4}>
            Our success stories
          </Text>
          <Text fontSize="lg" color="white" mb={4}>
            Discover Success Stories Directly from Our Valued Customers and
            Partners.
          </Text>
          <Button
            colorScheme="barbie"
            variant="solid"
            color="white"
            _hover={{ bg: "barbie.700" }}
            onClick={onClickContactUs}
          >
            Contact us
          </Button>
        </Flex>
      </Box>
      {useCases.map((useCase, index) => (
        <Flex
          key={useCase.title}
          direction={
            isMobile
              ? "column-reverse"
              : index % 2 === 0
              ? "row"
              : "row-reverse"
          }
          align="center"
          justify="center"
          w="100%"
          overflow="hidden"
          paddingLeft={[0, 20]}
          paddingRight={[0, 20]}
          paddingTop={[0, 20]}
          bg={useCase.inverse ? "gray.100" : "white"}
        >
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            p={[4, 8]}
          >
            <Text
              fontSize={["sm", "md"]}
              fontWeight="bold"
              mb={2}
              color="barbie.400"
            >
              {useCase.heading}
            </Text>

            <Text
              fontSize={["3xl", "4xl"]}
              fontWeight="bold"
              mb={4}
              color="action.400"
            >
              {useCase.title}
            </Text>
            <Text
              fontSize={["l", "xl"]}
              mb={4}
              color="gray.500"
              fontStyle={["italic", "bold"]}
            >
              "{useCase.quote}"
            </Text>
            <Heading size={fontSizeHeading} mb={3} color="gray.700">
              The challenge
            </Heading>
            <Text fontSize={fontSizeText} mb={4} color="gray.700">
              {useCase.challenge}
            </Text>
            <Heading size={fontSizeHeading} mb={3} color="gray.700">
              The solution
            </Heading>
            <Text fontSize={fontSizeText} mb={4} color="gray.700">
              {useCase.solution}
            </Text>
            <Heading size={fontSizeHeading} mb={3} color="gray.700">
              Outcome
            </Heading>
            <Text fontSize={fontSizeText} mb={4} color="gray.700">
              {useCase.outcome}
            </Text>
            <Button
              variant="ghost"
              bg="action.400"
              color="white"
              size="md"
              _hover={{ bg: "action.600" }}
              onClick={() => onClickContactUs()}
            >
              Find out more
              <ArrowRightIcon ml={2} />
            </Button>
          </Box>
          <Box flex="1" display={isMobile ? "none" : "block"} pb={[4, 20]}>
            <Image
              src={images[index]}
              alt="Description of image"
              objectFit="cover"
              w="80%"
              h="100%"
              borderRadius={50}
            />
          </Box>
        </Flex>
      ))}
    </Grid>
  );
};

export default UseCasePage;
